import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faStar } from '@fortawesome/free-solid-svg-icons';

const DarkModeToggle = () => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem('theme') === 'dark'
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  }, [darkMode]);

  const toggleDarkMode = (checked) => {
    setDarkMode(checked);
  };

  return (
    <div className="switch-overlay border-none relative right-0 transform translate-x-full -translate-y-1/2 top-1/2 bg-white dark:bg-stone-950 dark:text-gray-200 rounded-l-xl transition-all duration-300 ease-in-out h-20 w-8 flex justify-center items-center">
      {/* dark:bg-gray-800 */}
      <Switch
        onChange={toggleDarkMode}
        checked={darkMode}
        offColor="#ccc"
        onColor="#2e2e2e"
        offHandleColor="#fff"
        onHandleColor="#fff"
        uncheckedIcon={false}
        checkedIcon={false}
        height={20}
        width={48}
        handleDiameter={20}
        className="react-switch-vertical"
        checkedHandleIcon={
          <div className="moon flex justify-center items-center h-full">
            <FontAwesomeIcon icon={faMoon} style={{ transform: 'rotate(280deg)' }} color="#000" />
          </div>
        }
        uncheckedHandleIcon={
          <div className="star flex justify-center items-center h-full">
            <FontAwesomeIcon icon={faStar} style={{ transform: 'rotate(55deg)' }} color="#000" />
          </div>
        }
      />
      <style jsx>{`
        .react-switch-vertical {
          transform: rotate(90deg) translate(-80%, 75%);
          position: absolute;
          top: 50%;
          left: 50%;
          transform-origin: center center;
        }
        
        @media (max-width: 640px) {
          .switch-overlay {
            position: fixed;
            bottom: 0px;
            top: 0px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 100px;
            height: 20px;
            padding: 10px 0;
            display: flex;
            justify-content: center;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0.75rem;
          }

          .react-switch-vertical {
            transform: rotate(0deg) translate(0, 0);
            top: auto;
            left: 0;
            transform-origin: center center;
          }
            .star
            {
              rotate: 17deg;
            }
              .moon
              {
                rotate: 100deg;
              }
        }
      `}</style>
    </div>
  );
};

export default DarkModeToggle;